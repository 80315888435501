a{color:#00ACC1;}
a:hover{text-decoration:none;color:#0094A0;}
body{background-color:#37474F;color:#273237;}
h2{font-size:1.2em;color:#273237;}
li{list-style-type:none;}
ul{padding:0;}

.ac-dropdown{position:absolute;top:33px;left:0;right:0;border:1px solid #BDBDBD;
	background-color:#FFFFFF;z-index:10;}
.ac-dropdown .link-list{cursor:pointer;}
.ac-dropdown .link-list:hover{background-color:#F1F1F1;}
.ac-dropdown .link-list:active, .ac-dropdown .link-list.active{background-color:#00ACC1;color:#fff;}

.add-close-pos{right:0;top:0;}
.add-input-pr{padding-right:40px;}

.alert{font-size:0.8em;}
.alert .alert-danger{background-color:#FFD7DA;border-color:#FFD7DA;color:#D33D34;}
.alert .close{position:absolute;top:15px;right:10px;font-size:1rem;}
.alert-message{font-size:0.9rem;line-height:1.5;}
.alert-position{position:fixed;bottom:0.5rem;left:0.5rem;right:0.5rem;margin-bottom:0;z-index:100;}

label .badge{position:relative;top:2px;}
.badge-secondary{background-color:#273237;}

.bg-dk{background-color:#273237;}
.bg-blue{background-color:#00ACC1;}

.border-gray{border:1px solid #273237;}

.btn-xs{padding:0 .1rem;font-size:.8rem;}
.btn-xs i{position:relative;}
.btn.btn-primary{background-color:#00ACC1;border-color:#00A7B6;}
.btn.btn-primary:hover{background-color:#00A7B6;border-color:#0094A0;}
.btn.btn-primary:active{background-color:#0094A0;border-color:#0094A0;}
.btn.btn-gray{background-color:#37474F;color:#FFFFFF;}
.btn.btn-gray:hover{background-color:#273237;}
.btn.btn-light:focus{box-shadow:0 0 0 0.2rem rgba(0,123,255,.25);}

/* Code mirror */
.CodeMirror{width:100%;height:auto !important;font-size:0.9em;margin-left:0;}
.CodeMirror-linenumbers{}
.CodeMirror-gutters{background:transparent !important;border-right:1px solid transparent !important;}

.code-error-left{left:-7px;}
.close-pos{top:0.25rem;right:0.25rem;}

.cm-s-default .cm-string{color: #03838F;}
.cm-s-default .cm-number { color: #9C27B0; }
.cm-s-default .cm-property { color: #273237; }

.content-body{}
.content-body-flex{display:flex;flex-direction:row;}
.content-body-full{background-color:rgba(255,255,255,0.93);}
.content-body-scroll{overflow-y:scroll;height:calc(100vh - 42px);}
.content-body-left{width:40%;background-color:rgba(39,50,55,0.92);overflow-y:scroll;height:calc(100vh - 42px);padding-bottom:2rem;}
.content-body-right{width:60%;background-color:rgba(255,255,255,0.93);overflow-y:scroll;overflow-x:hidden;height:calc(100vh - 42px);padding-bottom:2rem;}
.content-body-left .content-title-label{color:#FFFFFF;font-size:0.9rem;}
.content-body-left-icon{float:left;}
.content-body-left-row{font-size:0.8rem;padding:0 0.5rem;position:relative;white-space:nowrap;
	text-overflow:ellipsis;overflow:hidden;}
.content-body-row:nth-child(even){background-color:rgba(207,216,221,0.5);}
.content-body-left-row{color:#F9F9F9;display:block;border-right:3px solid transparent;margin-right:0.25rem;}
.content-body-left-row:hover{background-color:rgba(255,255,255,0.13);color:#FFF;}
.content-body-left-row.active{border-right:3px solid #FFF;}
.content-body-left .content-title-option{color:#F9F9F9;}
.content-body-left .content-title-option:hover{color:#FFFFFF;}
.content-body-row{padding:0.2rem 0.5rem;font-size:0.9rem;}

.content-body-title{font-size:0.8rem;font-weight:500;color:#FFF;padding:0.5rem 0.5rem;
	background-color:rgba(39,50,55,0.9);display:block;
	margin-bottom:0.5rem;}

.content-body-group{background-color:rgba(39,50,55,0.9);color:#FFF;font-size:0.85rem;padding:0.25rem 0.25rem;
	border-bottom:1px solid #273237;font-weight:500;}
.content-body-group:hover{background-color:rgba(39,50,55,1);}

.content-group-title{font-size:0.85rem;margin-bottom:0;padding:0.25rem 0.5rem;background-color:#B3EBF2;}

.content-pill{display:inline-block;width:50%;min-width:320px;max-width:500px;position:relative;z-index:50;}
.content-section{padding:0;}
.content-title{font-size:1rem;color:#455963;display:block;position:relative;padding:0.5rem 0.5rem;margin-bottom:0;}
.content-title-light .content-title-option{color:#455963;}
.content-title-light .content-title-option:hover{color:#273237;}
.content-title-light .content-title-option.active{border-top:4px solid transparent;border-bottom:4px solid #455963;}
.content-title-nav-offset{padding-left:2.25rem;}
.content-title-option{padding:0.25rem 0.25rem;position:relative;cursor:pointer;}
.content-title-option i{}
.content-title-options{float:right;position:relative;}
.content-title-primary{background-color:rgba(39,50,55,0.84);border-bottom:1px solid #455963;}
.content-title-primary .content-title-label{color:#FFF;}
.content-title-primary .content-title-option{color:#F9F9F9;border-top:4px solid transparent;border-bottom:4px solid transparent;}
.content-title-primary .content-title-option:hover{color:#FFFFFF;}
.content-title-primary .content-title-option.active{border-top:4px solid transparent;border-bottom:4px solid #FFF;}
.content-title-sub .content-title-label{font-size:0.8rem;}
.content-title-sub .content-title-option{padding:0.3rem 0.1rem;}
.content-title-sub .content-title-option i{font-size:0.8rem;}
.content-title-option-super{position:absolute;font-size:7px;top:0;right:0;}

.content-title-option.error:after{content:'';background-color:#D33D34;position:absolute;right:2px;top:2px;
	width:5px;height:5px;border-radius:5px;}

.content-row{padding:0.25rem 0.5rem;background-color:#FFF;font-size:0.8rem;}
.content-row.error{color:#D33D34;}


.drop-menu{position:absolute;background-color:#FFF;color:#273237;
	padding:0.25rem;border-radius:0.25rem;z-index:999;border:1px solid #8FA3AE;
	left:0;right:0;}
.drop-menu-locations{top:6px;right:45px;left:auto;width:140px;position:fixed;z-index:10000;}

.content-title-options .drop-menu{top:20px;right:0;}
.drop-item{color:#273237;white-space:nowrap;font-size:0.8rem;padding:0.25rem 0.5rem;cursor:pointer;}
.drop-item:hover{background-color:#EDEFF1;}
.drop-item.active{background-color:#283592;color:#FFF;}

.dropdown-cover{position:fixed;top:0;bottom:0;left:0;right:0;background-color:transparent;}

.exp-ml{margin-left:12px;}
.exp-arrow{left:-16px;top:10px;border-radius:4px;width:14px;height:14px;
	position:relative;color:#FFFFFF;background-color:#273237;cursor:pointer;}
.exp-arrow i{
	position:relative;top:-6px;left:-2px;
}
.exp-path{position:absolute;width:10px;top:14px;bottom:10px;
	border-left:2px solid #273237;left:-10px;}
.exp-dot{position:absolute;left:-12px;top:14px;border-radius:4px;width:6px;height:6px;
	border:2px solid #333;background-color:#FFFFFF;z-index:2;cursor:pointer;}
.exp-line-end{position:absolute;bottom:0;top:16px;left:-12px;
	background-color:#FFFFFF;width:4px;z-index:1;}

.edit-name{cursor:text;opacity:0.9;}
.edit-name:hover{opacity:1;}

.feature-property-row:nth-child(even){background-color:rgba(143,163,174,0.1);}
.feature-row{padding:0;border:1px solid #8FA3AE;border-radius:0.25rem;margin:0 0.5rem 0.5rem 0.5rem;background-color:rgba(237,239,241,0.5) !important;}
.feature-row-title{padding:0.25rem 0.5rem;font-size:0.85rem;}
.feature-row-deploy{position:absolute;bottom:0.5rem;right:0.5rem;}

.field-border-bottom{border-bottom:1px solid #BDBDBD;}
.focus .field-border-bottom{border-bottom:1px solid #0687D1;}
.focus-layer-features{font-size:0.65rem;}

.flex-2{flex:2;}

.font-sm{font-size:0.7rem;}
.font-med{font-size:0.8rem;}
.font-lg{font-size:0.9rem;}

.form-group label{font-size:0.8em;}
.form-control{font-size:0.75rem;}
.form-control-sm{font-size:0.65rem;}
.form-control .btn{line-height:1.2;}
.form-control .btn-right{float:right;position:relative;top:-4px;right:-10px;}

.func-border{border:1px solid #546D79;}
.func-add{border:1px dashed #EEEEEE;padding:0.2rem;font-size:0.7em;
	cursor:pointer;}
.func-add label{display:none;}
.func-add:hover{border:1px dashed #E6E6E6;cursor:pointer;}
.func-add.open{border:1px dashed #616161;}
.func-add:hover label, .func-add.open label{color:#616161;display:block;cursor:pointer;}

.helper-icon{position:relative;}

.icon-btn{cursor:pointer;}
.icon-btn.white{color:#EFEBE9;}
.icon-btn.white:hover{color:#FFF;}

.icon-btn.gray{color:#37474F;}
.icon-btn.gray:hover{color:#273237;}

.info-content{padding:0.5rem;font-size:0.8rem;background-color:#;}

.inline-block{display:inline-block;}

.inspector-back{color:#AEAEAE;position:absolute;left:0;top:0;border:0;
	border-radius:0 3px 0 0;cursor:pointer;background-color:transparent;}
.inspector-back:hover{color:#747474;background-color: rgba(0, 0, 0, 0.05);}
.inspector-overflow{padding-top:6px;max-width:200px;max-height:200px;overflow:auto;}

.interactive{cursor:pointer;}


.layer-nav-link{border-bottom:3px solid transparent;cursor:pointer;}
.layer-nav-link.active{border-bottom:3px solid #273237;}

.layer-group-heading.open button{border-bottom-right-radius:0;border-bottom-left-radius:0;}

.layer-search-pos{right:34px;left:6px;top:6px;}

.left-col-title{line-height:34px;}
.left-col-title input{font-size:0.7rem;}

.link-list{color:#37474F;text-decoration:none;
	overflow-wrap:break-word;word-wrap:break-word;word-break: break-word;
	font-weight:400;text-overflow:ellipsis;overflow:hidden;white-space: nowrap;}
.link-list:hover{color:#273237;text-decoration:none;background-color:#FFF;}
.link-list.active{background-color:#FFF;}
.link-list.error{color:#D33D34;}

/*
.link-list.error:after{content:'';background-color:#D33D34;position:absolute;right:7px;top:7px;
	width:6px;height:6px;border-radius:4px;}
	*/

.list-border-right{border-right:3px solid #E6E6E6;}
.list-border-right.active{border-right:3px solid #273237;}

.list-right{position:absolute;right:8px;top:3px;}
.list-left{position:absolute;left:4px;top:3px;}

.list-layer-color{position:absolute;left:8px;top:0;}

.link-list.p-list{padding-left:28px!important;padding-right:24px!important;}
.link-list.pr-list{padding-right:30px!important;}
.link-list.pl-list{padding-left:30px!important;}

.map-control{cursor:pointer;}
.map-control:hover button{background-color:rgba(0,0,0,.05);}

.map-inspect-list{}
.map-inspect-list li{line-height:1.8;font-size:1.00em;cursor:pointer;}
.map-inspect-list li:hover{background:#EEEEEE;}
.map-inspect-list li:active{color:#FFFFFF;background:#00ACC1;}
.map-inspect-list a{color:inherit;}
.map-inspect-list .list-left{top:-1px;left:0;}

.mapboxgl-ctrl-icon.active{background-color:#00ACC1;color:#FFF;}
.mapboxgl-ctrl-icon.active:hover{background-color:#0197A7!important;color:#FFF;}
.mapboxgl-ctrl-icon i{position:relative;top:1px;font-size:1rem;}

.mapboxgl-popup-close-button{color:#AEAEAE;}
.mapboxgl-popup-close-button:hover{color:#747474;}

.marker{font-size:1.4rem;cursor: pointer;text-align:center;
	color:#FF9800;text-shadow: -1px 0 rgba(39,50,55,0.9), 0 1px rgba(39,50,55,0.9), 1px 0 rgba(39,50,55,0.9), 0 -1px rgba(39,50,55,0.9);
	position:relative;width:15px;
}
.marker-number{background-color:rgba(39,50,55,0.9);border-radius:0.15rem;padding:0.1rem 0.4rem;color:#FFF;font-size:0.7rem;
	position:absolute;left:22px;top:-3px;white-space:nowrap;}
.marker-close{margin-left:0.5rem;color:#EDEFF1;}
.marker-close:hover{color:#FFF;}

.modal{background-color:rgba(39,50,55,0.75);}
.modal-container{position:absolute;top:0;bottom:0;left:0;right:0;}
.modal-backdrop{position:absolute;top:0;bottom:0;left:0;right:0;background-color:rgba(39,50,55,0.6);}
.modal-content{position:relative;margin:0.75em;background:#fff;z-index:1041;}

.navbar-pill{display:inline-block;border-radius:0.25rem 0.25rem 0 0;padding:0.25rem;
	margin:0.5rem 0 0 0.5rem;}
.navbar-pill .navbar-nav{flex-direction:row;}
.navbar-pill .nav-item{padding:0 0.25rem;font-size:0.9rem;}
.navbar-pill .nav-link{padding:0.25rem;}
.navbar-pill .nav-img{max-width:0.9rem;max-height:0.9rem;position:relative;top:0.15rem;}

.nav-link{border-bottom:3px solid transparent;cursor:pointer;position:relative;color:#455963;}
.nav-item .active{}
/*.nav-link.active{border-bottom:3px solid #FFF;}*/

.nav-link.nav-bb.active{border-bottom:3px solid #FFF;}
.nav-link.error:after{content:'';background-color:#D33D34;position:absolute;right:7px;top:7px;
	width:6px;height:6px;border-radius:4px;}

/*.md-shadow{text-shadow:1px 1px rgba(0,0,0,0.2), 1px -1px rgba(0,0,0,0.1),
		-1px 1px rgba(0,0,0,0.2), -1px -1px rgba(0,0,0,0.2);}*/

.o-scroll{overflow:scroll;}
.o-y-scroll{overflow-y:auto;}

.page-loader{position:absolute;top:0;bottom:0;left:0;right:0;}
.page-loader-spinner{position:absolute;top:50%;left:50%;right:50%;bottom:50%;margin:-3rem;}

.panel-float{position:fixed;z-index:1000;}

.panel-min-height{min-height:300px;}
.panel-z-index{z-index:3;}

.primary-nav{position:fixed;top:0.4rem;left:0.5rem;z-index:100;}
.primary-nav-icon{width:1.25rem;}

.property{position:relative;}
.property-label{font-size:0.8rem;display:block;}
.property-label-options{float:right;color:#455963;}
.property.focus{}

.property .border-left{border-left:1px solid #EEEEEE;}
.property .error-icon{top:0;left:-15px;}

.property-content{padding:0.5rem;}

.right-col-title{line-height:30px;}

.row-btn-remove{position:absolute;right:0.5rem;top:0.15rem;}
.row-icons{padding:0 1.75rem 0 1.5rem;position:relative;}
.row-icon-left{position:absolute;left:0.25rem;top:0;}
.row-icon-right{position:absolute;right:0.25rem;top:0;}
.row-icon-right-second{right:1.3rem;}

.search-option{color:#EDEFF1;padding:2px 2px 1px 2px;cursor:pointer;}
.search-option:hover{color:#FFF;}

.swatch{width:22px;height:22px;cursor:pointer;}
.swatch:hover{border:1px solid #AEAEAE;}
.swatch-flex{flex:1;height:18px;border:1px solid transparent;}
.swatch-flex:hover{border:1px solid #FFF;}
.swatch-flex.active{border:1px solid #fff;}
.swatch-border{border:1px solid #E6E6E6;}
.swatch-pos{left:5px;top:5px;}
.swatch-input-pl{padding-left:38px;}

.text-flow-breaks{overflow-wrap: break-word;max-width:100%;}
.text-nav{line-height:34px;}
.text-overflow-ellipsis{text-overflow:ellipsis;overflow:hidden;white-space: nowrap;}

.infotip{font-size:0.7rem;position:absolute;top:0;bottom:0;left:0;right:0;}
.infotip-source{position:absolute;bottom:0;left:0;right:0;text-align:center;height:1px;}
.infotip.y.bigY .infotip-source{top:0;left:0;right:0;bottom:auto;}
.infotip.x .infotip-source{bottom:0;right:0;top:0;left:auto;height:auto;width:1px;}
.infotip.x.bigX .infotip-source{bottom:0;left:0;top:0;right:auto;}

.infotip-point{display:inline-block;position:relative;}
.infotip-bubble{position:absolute;left:-11px;z-index:1000;top:-4px;
	background-color:#1B2124;color:#FFFFFF;
	padding:0.5rem 0.75rem;border-radius:0.25rem;display:block;
	font-size:0.8rem;line-height:1.2;
	text-align:left;z-index:100;}
.infotip.y.bigY .infotip-bubble{bottom:20px;top:auto;}
.infotip.y.bigX .infotip-bubble{right:-11px;left:auto;}
.infotip.x .infotip-bubble{left:11px;top:-15px;}
.infotip.x.bigX .infotip-bubble{right:11px;left:auto;top:-15px;}

.infotip-bubble:after{content:'';position:absolute;left:6px;top:-4px;width:8px;height:4px;
  border-left:4px solid transparent;border-bottom:4px solid #1B2124;border-right:4px solid transparent;}
.infotip.y.bigY .infotip-bubble:after{bottom:-4px;top:auto;border-top:4px solid #1B2124;border-bottom:0;}
.infotip.y.bigX .infotip-bubble:after{right:6px;left:auto;}
.infotip.x .infotip-bubble:after{left:-4px;top:11px;right:auto;width:4px;height:8px;
	border-right:4px solid #1B2124;border-bottom:4px solid transparent;border-top:4px solid transparent;border-left:0;}
.infotip.x.bigX .infotip-bubble:after{right:-4px;left:auto;
	border-right:0;border-left:4px solid #1B2124;}

.infotip-text-long{min-width:120px;display:inline-block;}
.infotip-text-short{white-space: nowrap;}
.infotip-trigger{position:relative;}

.w-max-500{max-width:500px;}

#map{position:absolute;top:0;bottom:0;left:0;right:0;width:100%;}

